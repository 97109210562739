

import styled from "styled-components";
import { Select } from "antd";
import Colors from "infrastructure/utils/colors";

export const StyledSelect = styled(Select)`
  border-radius: 10px;
  margin-top: ${(props: any) =>
    props.className !== "evaluationTemplateWidth" ? "25px" : "unset"};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  margin: 13px 0;
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: ${(props: any) =>
    props.style ? Colors.lipstick_5 : Colors.black_4};
    border-radius: 10px;
    border-color: ${(props: any) =>
    props.style ? Colors.lipstick : Colors.black_4};
    align-items: baseline;
    width: ${(props: any) =>
    props.className === "evaluationTemplateWidth" ? "90%" : "unset"};
  }

  .ant-select-dropdown ant-select-dropdown-placement-topLeft {
    width: ${(props: { className: any }) =>
    props.className === 'settings' ? '250px' :
      props.className !== "jobForm" ? "180px" : ""};
  }

  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px;
    text-align:right;
  }

  &.ant-select {
    height: 51px;
    width: ${(props: { className: any }) =>
    props.className === "jobForm"
      ? "700px"
      : props.className === "jobs"
        ? "342px"
        : props.className === "price"
          ? "485px"
          : props.className === "faq"
            ? "90%"
            : props.className === "settings"
              ? "250px"
              : "100%"};
    margin-top: ${(props: { className: any }) =>
    props.className === "jobs" ||
      props.className === "price" ||
      props.className === "jobForm" ||
      props.className === "evaluationTemplateWidth" ||
      props.className === "company" ||
      props.className === "settings"
      ? "13px"
      : "unset"};
  }

  .ant-select-arrow {
    left: 11px;
    direction:ltr;
  }

  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0px !important;
  }

  .ant-select-selection-item {
    line-height: 49px !important;
    text-align: right;
    align-self: center;
    height: 100%;

  }

  .ant-select-item-option-selected {
    background-color: ${Colors.charcoal_grey_54} !important;
  }

  &.ant-select-multiple .ant-select-selection-item {
    background-color: unset;
    border-color: ${Colors.dark_mint};
    align-items: center;
    border-radius: 5px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${(props: any) =>
    props.style ? Colors.lipstick_5 : Colors.black_4};
    min-height: 51px;
    border-radius: 10px;
    border-color: ${(props: any) =>
    props.style ? Colors.lipstick : Colors.black_4};
    align-items: center;
  }

  .ant-select-selection-overflow {
    direction: rtl;
  }

  .ant-select-selection-overflow-item {
    direction: ltr;
  }

  .ant-select-selection-placeholder {
    font-family: Famtree;
    font-size: 14px;
    font-weight: normal;
    color: ${Colors.charcoal_grey};
    padding-right: 0px !important;
  }

  &.ant-select-multiple .ant-select-selection-placeholder {
    left: unset;
  }

  .ant-select-selection-placeholder {
    direction: rtl;
    line-height: 58px;
  }
`;
