/** @format */

import React from 'react';
import Header from 'common/Header';
import { Trans } from 'react-i18next';
import CreateFaqForm from './form';

const FaqSettings = () => {
  return (
    <>
      <Header
        title={<Trans>add_faq</Trans>}
        path='/settings'
        type='withoutLeftContainer'
      />
      <CreateFaqForm />
    </>
  );
};

export default FaqSettings;
