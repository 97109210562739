import styled from "styled-components";
import Colors from "infrastructure/utils/colors";
import { Button } from "antd";

export const Container = styled.div``;

export const Head = styled.p`
  font-size: 20px;
  color: ${Colors.charcoal_grey};
  font-weight: bold;
  font-family: Famtree;
  line-height: normal;
  text-align: end;
  padding-top: 29px;
`;

export const Card = styled.div`
  background-color: white;
  margin: 32px 0 0 30px;
  border-radius: 16px;
  box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  height: 744px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 83%;
`;

export const Pargraph = styled.div`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 18px;
  font-weight: normal;
  margin: 18px 0 34px;
`;

export const Span = styled.span`
  color: white;
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
`;

export const P = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  text-align: end;
  margin: 30px 43px 98px 0px;
`;

export const Label = styled.label`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 80%;
  margin: 0 auto;
`;

export const Hr = styled.div`
  width: 923px;
  color: "#dddddd";
  margin: 0 auto;
  height: 2px;
`;

export const SubP = styled.p`
  font-family: Famtree;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  color: ${Colors.charcoal_grey_54};
  text-align: center;
`;

export const ParagraphContainer = styled.div`
  margin: 0px auto;
  width: 923px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SupContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  margin: 0 auto;
`;

export const QuestionsHeadContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
`;

export const QuestionBodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  direction: rtl;
`;

export const ButtonContainer = styled.div`
  width: 947px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  padding: 23px 16px 18px 17px;
  align-items: flex-start;
`;

export const SwitchLabel = styled.div`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  color: ${Colors.charcoal_grey};
  margin-left: 41px;
  margin-right: 14px;
`;

export const SelectLabel = styled.div`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  color: ${Colors.charcoal_grey};
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  line-height: 51px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

export const OptionValue = styled.p`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  margin-right: 14px;
  margin-bottom: 0px;
  margin-top: 6px;
  text-align: right;
`;

export const QuestionTypeScale = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: baseline;

`;

export const MultiChoiceContainer = styled.div`
  width: 890px;
  position: relative;
`;

export const Icons = styled.div`
  position: absolute;
  min-width: 50px;
  text-align: center;
`;

export const MultiChoiceQuestionsContainer = styled.div`
  display: flex;
  flex-direction: coulmn;
`;

export const PContainer = styled.div`
  width: 1275px;
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  padding: 23px 16px 18px 17px;
  align-items: baseline;
  justify-content: flex-end;
`;

export const ColumnCell = styled.div`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  color: ${Colors.charcoal_grey};
`;
export const ColumnCellName = styled.div`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  cursor: pointer;
  color: ${Colors.charcoal_grey};
`;

export const MoreBtn = styled.div`
  font-family: Famtree;
  font-size: 36px;
  line-height: 0;
  margin-top: -10px;
  letter-spacing: -2px;
  cursor: pointer;
`;

export const Title = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  text-align: end;
  margin: 29px 0px 32px 0px;
`;

export const QuestionDropdownContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FlexWrpperBetween = styled.div`

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap:wrap;
  direction:rtl

`;

export const Wrapper = styled.div`
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 430px;
  .question-type-select,
  .question-focus-select {
    width: 100% !important;
    margin: 10px 0 !important;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  .checkbox-value {
    font-family: Famtree;
    font-size: 14px;
    font-weight: normal;
    color: #344247;
    margin-right: 14px;
  }
`;

export const CreateFormSection = styled.section`
  margin: 0px auto;
  width: 923px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;


export const SectionTitle = styled.p`
  font-family: Famtree;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  color: ${Colors.charcoal_grey_54};
  text-align: center;
  margin-top: 53px;
  margin-bottom: 18px;
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;


export const TabButton = styled(Button)`
  border-color: unset;
  font-family: Famtree;
  font-size: 14px;
  width: 236px;
  height: 50px;
  margin: 0 0 0 18px;
  padding: 12px 24px 17px 20px;
  border-radius: 5px;
  border: solid 0.5px #f7f7f7;
  color: #344247;
  border-color: #344247;
  background-color: #f7f7f7;
  font-weight: normal;
  border-color: #ccc;

  :focus {
    color: #344247;
    border-color: #344247;
    background-color: #f7f7f7;
    font-weight: normal;
  }

  :hover {
    color: #344247;
    border-color: #344247;
  }

  &.active {
    border: solid 1px #44c874;
    color: #44c874;
    border-color: #44c874;
    background-color: #daf4e3;
    font-weight: bold;

    :focus {
      color: #44c874;
      border-color: #44c874;
      background-color: #daf4e3;
      font-weight: bold;
    }
    :hover {
      color: #44c874;
      border-color: #44c874;
    }
  }
`;

export const EmptyState = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmptyMessage = styled.p`
  font-family: Famtree;
  font-size: 17px;
  font-weight: bold;
  color: ${Colors.charcoal_grey_70};
`;

export const ChoiceOptionsWithPoints = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const DrawerBodyTitle = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: #344247;
  text-align: right;
`;


export const ExampleSpan = styled.p`
  font-family: Famtree;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  color: #344247;
`;

export const ScaleDetailsBox = styled.div`
  border-radius: 10px;
  border: solid 1px rgba(221, 221, 221, 0.3);
  background-color: #f7f7f7;
  padding: 16px 16px 17px 50px;
`;

export const OptionsAndPointsLabels = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 24px;
  width: 83%;
  justify-content: space-between;
`;

export const InfoLabel = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 10%;
  justify-content: space-between;
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
`;

export const SliderWrapper = styled.div`
  width: 90%;
`;


export const WeightAndPointsText = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  color: #344247;
  margin-left: 10px;
`;


export const WeightWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
`;

export const StyledP = styled.p`
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  color: ${Colors.charcoal_grey};
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;