import Colors from "infrastructure/utils/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  border: 1px solid ${Colors.light_gary_2};
  border-radius: 3%;
  padding: 35px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const EditorContainer = styled.div`
  width: 100%;
  height: 100%;
`;
