/** @format */

import React, { FC } from 'react';
import { Props } from 'infrastructure/interfaces/button.types';
import AntDButton from './style';

const Button: FC<Props> = ({
  onClick,
  disabled,
  loading,
  shape,
  size,
  icon,
  children,
  className,
  style,
  htmlType,
  type,
  name,
}): JSX.Element => {
  return (
    <AntDButton
      onClick={onClick}
      disabled={disabled}
      htmlType={htmlType}
      loading={loading}
      shape={shape}
      size={size}
      icon={icon}
      className={className}
      style={style}
      type={type}
      name={name}>
      {children}
    </AntDButton>
  );
};

Button.defaultProps = {
  onClick: () => {},
  disabled: false,
  type: 'text',
  loading: false,
  size: 'middle',
};

export default Button;
