

import styled from "styled-components";
import Colors from "infrastructure/utils/colors";

export const InputStyle = styled.input`
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  width: ${(props: { width: string; id: string }) =>
    props.width === "login" || props.width === "100"
      ? "100%"
      : props.id === "options"
        ? "100%"
        : props.width === "settings"
          ? "250px"
          : props.width === "optionsWidth"
            ? "30%"
            : props.width === "settingsModules"
              ? "90%"
              : props.width === "points"
                ? "25%"
                : "890px"};

  height: 50px;
  padding: ${(props) =>
    props.width === "price" || props.width === "login"
      ? "16px 10px 16px 25px"
      : props.width === "exp" || props.width === "optionsWidth"
        ? "16px 10px 16px 15px"
        : props.dir === "true"
          ? "16px 10px 16px 85px"
          : "16px 10px 16px 25px"};

  padding-left: ${(props) =>
    props.name === "password" ? "40px" : props.id === "options" && "75px"};

  display: inline-block;
  border: 1px solid;
  background-color: ${(props: any) =>
    props.errorName ? Colors.lipstick_5 : Colors.black_4};
  border-radius: 10px;
  box-sizing: border-box;
  margin: 13px 0;

  border-color: ${(props: any) =>
    props.errorName ? Colors.lipstick : Colors.black_4};

  :focus {
    outline: none !important;
    border: solid 0.1px
      ${(props: any) =>
    props.errorName ? Colors.lipstick : '#40a9ff'};
  }

  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;

  ::placeholder {
    font-family: Famtree;
    font-size: 11px;
    color: ${Colors.charcoal_grey_38};
    direction: rtl;
    text-align: right;
  }
  direction: rtl;

`;

export const P = styled.p`
  position: absolute;
  margin-left: 108px;
  margin-top: -50px;
`;
