/** @format */
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/ErrorFallback';
import GlobalFonts from 'assets/fonts/fonts';
import NotFound from 'common/NotFound';
import Login from 'pages/User/Login';
import Protected from 'routes/protected';
import protectedRoutes from 'routes/routes';

function App() {
  const routes = protectedRoutes.map((route: any, index: number) => {
    return (
      <Protected
        key={index}
        name={route.name}
        exact={route.exact}
        path={route.path}
        component={route.component}
        requestedRole={route.requestedRole}
      />
    );
  });

  return (
    <Router>
      {/* @ts-ignore  */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <GlobalFonts />
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route exact path='/' component={Login} />
          {routes}
          <Route path='*' component={NotFound} />
        </Switch>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
