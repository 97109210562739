/** @format */

import FaqSettings from 'pages/FaqSettings/Create';
import EditFaqSettings from 'pages/FaqSettings/Edit';
import Notifications from 'pages/Notifications';
import { lazy } from 'react';

const Home = lazy(() => import('pages/Home'));
const Visits = lazy(() => import('pages/Visits'));
const Settings = lazy(() => import('pages/Settings'));
const Roles = lazy(() => import('pages/Roles'));
const Admin = lazy(() => import('pages/Admin'));
const CreateAdmin = lazy(() => import('pages/Admin/Create'));
const EditAdmin = lazy(() => import('pages/Admin/Edit'));
const PagesSettings = lazy(() => import('pages/PagesSettings/Create'));
const EditPageForm = lazy(() => import('pages/PagesSettings/Edit'));
const Companies = lazy(() => import('pages/Companies'));
const CompaniesDetails = lazy(() => import('pages/Companies/Details'));
const Shoppers = lazy(() => import('pages/Shoppers'));
const ShoppersDetails = lazy(() => import('pages/Shoppers/Details'));
const EvaluationTemplates = lazy(() => import('pages/EvaluationTemplates'));
const EvaluationDetails = lazy(
  () => import('pages/EvaluationTemplates/details')
);
const CreateEvaluationTemplate = lazy(
  () => import('pages/EvaluationTemplates/Create')
);
const EditEvaluationTemplate = lazy(
  () => import('pages/EvaluationTemplates/Edit')
);

const Jobs = lazy(() => import('pages/Jobs'));
const JobsDetails = lazy(() => import('pages/Jobs/Details'));

const Financials = lazy(() => import('pages/Financials'));
const Discounts = lazy(() => import('pages/Discounts'));
const DiscountForm = lazy(() => import('pages/Discounts/Form'));
const EditSummary = lazy(() => import('pages/Visits/SummaryEdit'));

const Alerts = lazy(() => import('pages/Alerts'));

const routes: any = [
  {
    path: ['/home'],
    exact: true,
    name: 'Home',
    component: Home,
    requestedRole: ['admin', 'editor', 'accountant'],
  },
  {
    path: ['/visits'],
    exact: true,
    name: 'Visits',
    component: Visits,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/settings'],
    exact: true,
    name: 'Settings',
    component: Settings,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/roles'],
    exact: true,
    name: 'Roles',
    component: Roles,
    requestedRole: ['admin'],
  },
  {
    path: ['/admins'],
    exact: true,
    name: 'Admins',
    component: Admin,
    requestedRole: ['admin'],
  },
  {
    path: ['/admins/create'],
    exact: true,
    name: 'CreateAdmin',
    component: CreateAdmin,
    requestedRole: ['admin'],
  },
  {
    path: ['/admins/edit/:id'],
    exact: true,
    name: 'EditAdmin',
    component: EditAdmin,
    requestedRole: ['admin'],
  },
  {
    path: ['/settings/pages/create'],
    exact: true,
    name: 'CreatePage',
    component: PagesSettings,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/settings/pages/edit/:id'],
    exact: true,
    name: 'EditPage',
    component: EditPageForm,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/companies'],
    exact: true,
    name: 'Companies',
    component: Companies,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/companies/:id'],
    exact: true,
    name: 'CompaniesDetails',
    component: CompaniesDetails,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/shoppers'],
    exact: true,
    name: 'Shoppers',
    component: Shoppers,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/shoppers/:id'],
    exact: true,
    name: 'ShoppersDetails',
    component: ShoppersDetails,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/evaluation-templates'],
    exact: true,
    name: 'EvaluationTemplates',
    component: EvaluationTemplates,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/evaluation-templates/create'],
    exact: true,
    name: 'CreateEvaluationTemplate',
    component: CreateEvaluationTemplate,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/evaluation-templates/:id'],
    exact: true,
    name: 'EvaluationTemplatesDetails',
    component: EvaluationDetails,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/evaluation-templates/edit/:id'],
    exact: true,
    name: 'EditEvaluationTemplates',
    component: EditEvaluationTemplate,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/jobs'],
    exact: true,
    name: 'Jobs',
    component: Jobs,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/jobs/:id'],
    exact: true,
    name: 'JobsDetails',
    component: JobsDetails,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/financials'],
    exact: true,
    name: 'financials',
    component: Financials,
    requestedRole: ['admin', 'accountant'],
  },
  {
    path: ['/discounts'],
    exact: true,
    name: 'Discounts',
    component: Discounts,
    requestedRole: ['admin', 'accountant'],
  },
  {
    path: ['/alerts'],
    exact: true,
    name: 'Alerts',
    component: Alerts,
    requestedRole: ['admin'],
  },
  {
    path: ['/discounts/create'],
    exact: true,
    name: 'DiscountForm',
    component: DiscountForm,
    requestedRole: ['admin', 'accountant'],
  },
  {
    path: ['/visit/:id/edit'],
    exact: true,
    name: 'EditSummary',
    component: EditSummary,
    requestedRole: ['admin', 'accountant'],
  },
  {
    path: ['/notifications'],
    exact: true,
    name: 'Notifications',
    component: Notifications,
    requestedRole: ['admin', 'accountant', 'editor'],
  },
  {
    path: ['/settings/faq/create'],
    exact: true,
    name: 'CreatePage',
    component: FaqSettings,
    requestedRole: ['admin', 'editor'],
  },
  {
    path: ['/settings/faq/edit/:id'],
    exact: true,
    name: 'EditPage',
    component: EditFaqSettings,
    requestedRole: ['admin', 'editor'],
  },
];

export default routes;
