/** @format */

import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';
import { Span, P } from './style';
import storage from 'infrastructure/utils/storage';

const Logout = () => {
  const history = useHistory();
  return (
    <>
      <Span
        className='fa fa-power-off fa-lg'
        onClick={() => {
          storage.clearItem('access_token');
          storage.clearItem('role');
          history.push('/login');
        }}
      >
        <P>
          <Trans>logout</Trans>
        </P>
      </Span>
    </>
  );
};

export default Logout;
