import styled from "styled-components";
import Colors from "infrastructure/utils/colors";

export const P = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  margin-bottom: 0px;
  margin-right: 10px;
`;

export const Span = styled.span`
  margin-left: 35px;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
`;
