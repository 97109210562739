/** @format */

import React from 'react';
import { useHistory } from 'react-router-dom';
import IProps from 'infrastructure/interfaces/header.interface';
import DeleteIcon from 'assets/svg/icon-delete';
import EditIcon from 'assets/svg/edit-icon';
import {
  RightContainer,
  LeftContainer,
  P,
  Container,
  BackIcon,
  CancelIcon,
} from './style';

const Header = ({
  title,
  editPath,
  type,
  handleDeleteIcon,
  handleCancelJob,
  status,
  component,
}: IProps) => {
  const history = useHistory();

  const onRenderEdit = () => {
    if (component !== 'jobs') {
      if (component === 'evaluation-templates' && status === 1) {
        return <EditIcon onClick={() => history.push(editPath)} />;
      }
    }
  };

  return (
    <Container component={component}>
      {type === 'withoutLeftContainer' ? (
        <RightContainer>
          <P>{title}</P>
          <BackIcon
            className='fa fa-long-arrow-right'
            onClick={() => {
              history.goBack();
            }}
          />
        </RightContainer>
      ) : (
        <>
          <RightContainer>
            <P>{title}</P>
            <BackIcon
              className='fa fa-long-arrow-right'
              onClick={() => {
                history.goBack();
              }}
            />
          </RightContainer>
          <LeftContainer>
            {component === 'jobs' && status === 1 && (
              <EditIcon onClick={() => history.push(editPath)} />
            )}

            {component === 'jobs' && status !== 6 && status !== 7 && (
              <>
                <DeleteIcon onClick={handleDeleteIcon} />
                <CancelIcon
                  className='fa fa-times-circle'
                  onClick={handleCancelJob}
                />
              </>
            )}

            {onRenderEdit()}
          </LeftContainer>
        </>
      )}
    </Container>
  );
};

export default Header;
