const Colors = {
  green: "#44c874",
  green_12: "rgba(68, 200, 116, 0.12)",
  black_4: "rgba(48, 48, 48, 0.04)",
  battleship_grey: "#737c7f",
  charcoal_grey: "#344247",
  sunflower: "#fcd00f",
  charcoal_grey_70: "rgba(52, 66, 71, 0.7)",
  white: "#ffffff",
  charcoal_grey_38: "rgba(52, 66, 71, 0.38)",
  cool_green: "#33be66",
  darkish_pink: "#de3a66",
  lipstick: "#d60d42",
  lipstick_5: "rgba(214, 13, 66, 0.05)",
  main_background_color: "#ececec",
  charcoal_grey_54: "rgba(52, 66, 71, 0.54)",
  dark_mint: "#44c874",
  black_0: "rgba(48, 48, 48, 0.01)",
  light_gray: "rgba(52, 66, 71, 0.1)",
  red: "#fc603b",
  shadow: "rgba(52, 66, 71, 0.12)",
  border: "#e8e8e8",
  gray: "#e6e8e9",
  mint_gray: "#91999b",
  black: "#374449",
  orange: "#f88639",
  blue: "#2d8ec0",
  yellow: "#f4c714",
  dark_yellow: "#ad9240",
  dark_mint_2: "rgba(68, 200, 116, 0.2)",
  mint_red: "#fda59f",
  light_gary_2: "rgba(52, 66, 71, 0.2)",
  dark_green: "#065c54",
};

export default Colors;
