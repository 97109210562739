/** @format */

import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Input from 'common/Input';
import { useForm } from 'react-hook-form';
import FetchRequests from 'infrastructure/utils/fetchRequests';
import { useQuery, useQueryClient } from 'react-query';
import Button from 'common/Button';
import { useHistory, useParams } from 'react-router';
import TextArea from 'common/TextArea';
import Select from 'common/Select';
import { Select as AntDSelect } from 'antd';
import { OptionValue } from 'pages/EvaluationTemplates/style';
import { Error } from 'pages/style';
import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { Container, Form } from '../style';
import { Label } from '../../../style';

const { Option } = AntDSelect;

const EditFaqForm = () => {
  const history = useHistory();
  const { id }: any = useParams();

  const handleUpdateFaq = new FetchRequests();
  const queryClient = useQueryClient();

  const { data } = useQuery(['faqDetails'], () =>
    fetchGetRequests(`api/admin/v1/help-questions/${id}`),
  );

  const { t } = useTranslation();
  const { handleSubmit, register, errors, formState }: any = useForm<{
    answer: string;
    target: string;
  }>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      answer: data?.response.question.answer,
      target: '',
    },
  });

  const [selectValue, setSelectValue] = useState('');
  const [answerValue, setAnswerValue] = useState('');
  const handleSelectOnChange = (value: React.SetStateAction<string>) => {
    setSelectValue(value);
  };

  const onSubmit = (formData: any) => {
    const updatedData = {
      title: formData.title,
      answer: answerValue ? answerValue : data?.response.question.answer,
      target: selectValue ? selectValue : data?.response.question.target.name,
    };

    handleUpdateFaq.updateRequests(
      `api/admin/v1/help-questions/${id}`,
      updatedData,
      queryClient,
      'faqDetails',
      history.push('/settings'),
      t('done'),
      t('done_message'),
    );
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label>{t('page_title')}</Label>
        <Input
          type='text'
          name='title'
          defaultValue={data?.response.question.title}
          errorName={errors.title}
          width='settingsModules'
          ref={register({ required: true })}
        />
        {errors?.title?.types?.required && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}

        <Label>
          <Trans>target</Trans>
        </Label>
        <Select
          placeholder={data?.response.question.target.translation}
          onChange={(value: any) => handleSelectOnChange(value)}
          className='faq'>
          {[
            {
              id: 1,
              nameAr: 'الشركات',
              nameEn: 'companies',
            },
            {
              id: 2,
              nameAr: 'المتسوقين',
              nameEn: 'users',
            },
            {
              id: 3,
              nameAr: 'الكل',
              nameEn: 'all',
            },
          ]?.map((item) => {
            return (
              <Option key={item.id} value={item.nameEn}>
                <OptionValue>{item.nameAr}</OptionValue>
              </Option>
            );
          })}
        </Select>

        <Label>{t('page_answer')}</Label>
        <TextArea
          name='answer'
          defaultValue={data?.response.question.answer}
          errorName={errors.answer}
          inputRef={register({ required: true })}
          errorStyle={errors.answer}
          onChange={({ target: { value } }: any) => {
            setAnswerValue(value);
          }}
          style={{ width: '90%' }}
        />
        {errors?.answer && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}

        <Button
          type='primary'
          onClick={handleSubmit(onSubmit)}
          disabled={formState.isSubmitting}
          size='large'>
          <Trans>save</Trans>
        </Button>
      </Form>
    </Container>
  );
};

export default EditFaqForm;
