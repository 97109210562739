/** @format */

import React, { FunctionComponent } from 'react';
import { Spin } from 'antd';
import AntDSpace from './style';

const Spinner: FunctionComponent<{
  size: 'small' | 'default' | 'large' | undefined;
  tip?: string;
}> = ({ size, tip }): JSX.Element => {
  return (
    <div>
      <AntDSpace size='middle'>
        <Spin size={size} tip={tip} />
      </AntDSpace>
    </div>
  );
};
export default Spinner;
