import { QueryClient } from "react-query";


import {
  NotificationStatus,
  showNotification,
} from "../helpers/showNotifications";
import axiosApiInstance from "./api";

class FetchRequests {
  postRequests(
    request: string,
    variables?: any,
    invalidateQuery?: QueryClient | undefined | any,
    invalidateQueryName?: string,
    visiability?: any,
    message?: any,
    doneMessage?: any
  ) {
    axiosApiInstance
      .post(request, variables)
      .then(async () => {
        invalidateQuery
          ? invalidateQuery.invalidateQueries(invalidateQueryName)
          : null;
        showNotification(NotificationStatus.Success, message, doneMessage);
        
      })
      .catch((error) => {
        showNotification(
          NotificationStatus.Error,
          "Error",
          error?.response?.data.message
        );
      });
  }

  deleteRequests(
    request: string,
    invalidateQuery?: QueryClient | undefined | any,
    invalidateQueryName?: string,
    visiability?: any,
    message?: any,
    doneMessage?: any
  ) {
    axiosApiInstance
      .delete(request)
      .then(async () => {
        invalidateQuery.invalidateQueries(invalidateQueryName);
        showNotification(NotificationStatus.Success, message, doneMessage);
      })
      .catch((error) => {
        showNotification(
          NotificationStatus.Error,
          "Error",
          error?.response?.data.message
        );
      });
  }

  updateRequests(
    request: string,
    variables?: any,
    invalidateQuery?: QueryClient | undefined | any,
    invalidateQueryName?: string,
    visibility?: any,
    message?: any,
    doneMessage?: any
  ) {
    return axiosApiInstance
      .put(request, variables)
      .then(async () => {
        invalidateQuery.invalidateQueries(invalidateQueryName);
        message &&
          showNotification(NotificationStatus.Success, message, doneMessage);
      })
      .catch((error) => {
        showNotification(
          NotificationStatus.Error,
          "Error",
          error?.response?.data.message
        );
      });
  }
}

export default FetchRequests;
