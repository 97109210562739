
import styled from "styled-components";
import Colors from "infrastructure/utils/colors";

const StyledTextArea = styled.textarea`
  height: 226px;
  margin: 13px 0;
  border: 1px solid;
  background-color: ${(props: any) =>
    props.errorName ? Colors.lipstick_5 : Colors.black_4};
  border-radius: 10px;
  box-sizing: border-box;
  border-color: ${(props: any) =>
    props.errorName ? Colors.lipstick : Colors.black_4};

  colro: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  text-align: end;
  padding-right: 10px;
  padding-top: 20px;

  ::placeholder {
    font-family: Famtree;
    font-size: 11px;
    color: ${Colors.charcoal_grey_38};
  }
`;

export default StyledTextArea;
