import Colors from "infrastructure/utils/colors";
import styled from "styled-components";

export const ColumnCell = styled.div`
  font-family: Famtree;
  font-size: 11px;
  font-weight: normal;
  display: flex;
  color: ${Colors.charcoal_grey};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const RecordName = styled.a`
  font-family: Famtree;
  font-size: 11px;
  font-weight: bold;
  color: ${Colors.dark_mint};

  :hover {
    color: ${Colors.dark_mint};
  }
`;

export const ModalTitle = styled.p`
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  color: ${Colors.charcoal_grey};
  direction: rtl;
`;

export const Label = styled.p`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  margin: 0px;
`;

export const LabelValue = styled.p`
  color: #000;
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
`;

export const DeleteMessage = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
`;


