import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import languageEN from "./locales/en/translate.json";
import languageAR from "./locales/ar/translate.json";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: languageEN,
    ar: languageAR,
  },
  fallbackLng: "ar",
  lng: "ar",
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },
});

export default i18n;
