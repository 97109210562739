/** @format */

import React from 'react';
import Header from 'common/Header';
import { Trans } from 'react-i18next';
import Form from './form';

const EditFaqSettings = () => {
  return (
    <>
      <Header
        title={<Trans>edit_faq</Trans>}
        path='/settings'
        type='withoutLeftContainer'
      />
      <Form />
    </>
  );
};

export default EditFaqSettings;
