/** @format */

import axios from 'axios';
import {
  NotificationStatus,
  showNotification,
} from 'infrastructure/helpers/showNotifications';
import i18n from 'i18next';
import storage from './storage';

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_BASE_API_URL;
    config.headers = {
      Authorization: `Bearer ${storage.getItem('access_token')}`,
      Accept: 'application/json',
      'X-Authorization': process.env.REACT_APP_API_KEY_WEB_ADMIN,
      'Content-Type': 'application/json',
      'Accept-Language': i18n.language,
    };
    config.params = { ...config.params };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function Error(error) {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      showNotification(
        NotificationStatus.Warning,
        'Un Authenticated',
        error.response?.data.message,
      );
      storage.clearItem('access_token');
      return (window.location.href = '/login');
    }

    if (error.response?.status === 402) {
      showNotification(
        NotificationStatus.Error,
        'Internal Server Error',
        error.response.data.message,
      );
    }

    if (error.response?.status === 500) {
      showNotification(
        NotificationStatus.Error,
        'Internal Server Error',
        error.response.data.message,
      );
    }
    return Promise.reject(error);
  },
);

export default axiosApiInstance;
