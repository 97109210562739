/** @format */

import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 378 690'>
      <g
        fill='none'
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
        opacity='0.268'>
        <g transform='translate(-1302 -157)'>
          <g transform='translate(1198.5 -.5)'>
            <g transform='translate(104 68)'>
              <g transform='translate(0 354)'>
                <path
                  fill='#44C874'
                  d='M330.338 0c182.44 0 330.338 147.897 330.338 330.338 0 70.282-21.95 135.438-59.366 188.987l168.547 168.55a8 8 0 010 11.313l-78.618 78.618a8 8 0 01-11.314 0L509.824 607.707c-51.677 33.51-113.31 52.969-179.486 52.969C147.898 660.676 0 512.778 0 330.338 0 147.898 147.897 0 330.338 0z'
                />
                <path
                  fill='#FFF'
                  d='M328.592 180.184c85.28 0 154.414 69.134 154.414 154.414 0 85.28-69.134 154.414-154.414 154.414-85.28 0-154.414-69.134-154.414-154.414 0-31.078 9.181-60.012 24.98-84.237 12.056 14.76 30.395 24.18 50.935 24.18 36.304 0 65.733-29.43 65.733-65.732 0-9.815-2.15-19.126-6.006-27.49a154.938 154.938 0 0118.772-1.135z'
                />
              </g>
              <g fill='#FCD00F' transform='translate(274)'>
                <path d='M330.338 0c182.44 0 330.338 147.897 330.338 330.338 0 70.282-21.95 135.438-59.366 188.987l168.547 168.55a8 8 0 010 11.313l-78.618 78.618a8 8 0 01-11.314 0L509.824 607.707c-51.677 33.51-113.31 52.969-179.486 52.969C147.898 660.676 0 512.778 0 330.338 0 147.898 147.897 0 330.338 0z' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
