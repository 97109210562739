/** @format */

import * as React from 'react';

function EditIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width={17}
      height={18}
      style={
        props.register && {
          position: 'absolute',
          top: '153%',
          left: ' 34%',
        }
      }
      viewBox='0 0 17 18'>
      <g
        stroke='#344247'
        strokeWidth={1.5}
        opacity={0.54}
        fill='none'
        fillRule='evenodd'>
        <path
          strokeLinejoin='round'
          d='M.722 17.053l1.32-4.618a.862.862 0 01.226-.385l9.837-9.837a2.444 2.444 0 013.456 3.457l-9.837 9.837a.89.89 0 01-.384.227L.722 17.053z'
        />
        <path d='M10.772 3.547l3.456 3.456' />
      </g>
    </svg>
  );
}

export default EditIcon;
