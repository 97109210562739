/** @format */
import { Container, Header, P } from 'pages/style';
import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../style';
import Logout from 'components/Logout';
import { Form } from 'antd';
import Select from 'common/Select';
import { Select as AntDSelect } from 'antd';
import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { useQuery } from 'react-query';
import { Key, useState } from 'react';
import TextArea from 'common/TextArea';
import Input from 'common/Input';
import Button from 'common/Button';
import axiosApiInstance from 'infrastructure/utils/api';
import {
  showNotification,
  NotificationStatus,
} from 'infrastructure/helpers/showNotifications';
import { ModalTitle } from 'style';
import Modal from 'common/Modal';
const { Option } = AntDSelect;

const Notifications = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [notificationValues, setNotificationValues] = useState(null);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values: any) => {
    if (values.city_id === 'الكل') {
      delete values.city_id;
    }
    setNotificationValues(values);
  };

  const handleModalSubmit = () => {
    setLoading(true);
    axiosApiInstance
      .post('api/admin/v1/notifications', notificationValues)
      .then(() => {
        showNotification(
          NotificationStatus.Success,
          t('done'),
          t('done_message')
        );
        form.resetFields();
        setIsModalVisible(false);
        setLoading(false);
      })
      .catch((err) => {
        form.resetFields();
        setLoading(false);
        showNotification(
          NotificationStatus.Error,
          ' ',
          err.response.data.message
        );
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const { data: cities } = useQuery(
    ['cities'],
    () => fetchGetRequests('api/countries/1/cities'),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  return (
    <>
      <Header>
        <Title>
          <Trans>notifications</Trans>
        </Title>
        <Logout />
      </Header>
      <Container>
        <Form
          noValidate
          form={form}
          {...formItemLayout}
          name='basic'
          style={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            fontFamily: 'Famtree',
            textAlign: 'right',
            direction: 'rtl',
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Form.Item
            label={t('shopper_status')}
            name='shopper_state'
            style={{ alignItems: 'baseline' }}
            rules={[{ required: true, message: t('required_error_message') }]}
          >
            <Select defaultValue={t('activate')}>
              {[
                { value: 'active', label: t('activate') },
                { value: 'new_user', label: t('new_user') },
                { value: 'all', label: t('all') },
              ].map((item, index) => (
                <Option key={index} value={item.value}>
                  <P>{item.label}</P>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t('city')}
            name='city_id'
            style={{ alignItems: 'baseline' }}
            rules={[{ required: true, message: t('required_error_message') }]}
          >
            <Select defaultValue={t('city')}>
              <Option value={t('all')}>
                <P>{t('all')}</P>
              </Option>
              {cities?.response.cities.map(
                (city: { id: Key | null | undefined; name: string }) => (
                  <Option key={city.id} value={city.id}>
                    <P>{city.name}</P>
                  </Option>
                )
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label={t('notification_title')}
            name='title'
            style={{ alignItems: 'baseline' }}
            rules={[{ required: true, message: t('required_error_message') }]}
          >
            <Input width='login' />
          </Form.Item>

          <Form.Item
            label={t('message')}
            name='text'
            style={{ alignItems: 'baseline' }}
            rules={[{ required: true, message: t('required_error_message') }]}
          >
            <TextArea
              maxLength={200}
              style={{
                width: '100%',
                height: 'auto',
                textAlign: 'right',
              }}
              placeholder={t('notification_message')}
            />
          </Form.Item>

          <Form.Item style={{ alignSelf: 'flex-end', width: '100%' }}>
            <Button
              htmlType='submit'
              type='primary'
              size='large'
              shape='default'
              onClick={async () => {
                (await form.validateFields()) && showModal();
              }}
            >
              {t('send')}
            </Button>
          </Form.Item>
        </Form>
      </Container>
      <Modal
        title={<ModalTitle>{t('notification_confirmation_title')}</ModalTitle>}
        visible={isModalVisible}
        onOk={handleModalSubmit}
        onCancel={handleCancel}
        loading={loading}
      >
        <Trans>notification_body</Trans>
      </Modal>
    </>
  );
};

export default Notifications;
