/** @format */

import React, { forwardRef } from 'react';
import StyledTextArea from './style';

const TextArea = forwardRef<HTMLInputElement, any>((props: any, ref) => {
  return <StyledTextArea {...props} ref={ref} />;
});

export default TextArea;
