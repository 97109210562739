/** @format */

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Input from 'common/Input';
import { useForm } from 'react-hook-form';
import Button from 'common/Button';
import { useHistory } from 'react-router';
import TextArea from 'common/TextArea';
import Select from 'common/Select';
import { Select as AntDSelect } from 'antd';
import { OptionValue } from 'pages/EvaluationTemplates/style';
import axiosApiInstance from 'infrastructure/utils/api';
import {
  NotificationStatus,
  showNotification,
} from 'infrastructure/helpers/showNotifications';
import { Error, SelectLabel } from 'pages/style';
import { Container, Form } from '../style';
import { Label } from '../../../style';

const { Option } = AntDSelect;

const CreateFaqForm = () => {
  const history = useHistory();

  const { t } = useTranslation();
  const { handleSubmit, register, errors, formState, setValue }: any = useForm<{
    answer: string;
    target: string;
  }>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      answer: '',
      target: '',
    },
  });

  const [selectValue, setSelectValue] = useState('');
  const [error, setError] = useState(false);

  const handleSelectOnChange = (value: React.SetStateAction<string>) => {
    setSelectValue(value);
  };

  useEffect(() => {
    register('answer', {
      validate: (value: string) => value || 'This is required.',
    });
  }, [register]);

  const onSubmit = (formData: any) => {
    const data = {
      title: formData.title,
      answer: formData.answer.target.value,
      target: selectValue,
    };

    axiosApiInstance
      .post('api/admin/v1/help-questions', data)
      .then(() => {
        showNotification(
          NotificationStatus.Success,
          t('done'),
          t('done_message'),
        );
        history.push('/settings');
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label>{t('page_title')}</Label>
        <Input
          type='text'
          name='title'
          errorName={errors.title}
          ref={register({ required: true })}
          width='settingsModules'
        />
        {errors?.title?.types?.required && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}

        <Label>
          <Trans>target</Trans>
        </Label>
        <Select
          placeholder={
            <SelectLabel>
              <Trans>select-target</Trans>
            </SelectLabel>
          }
          onChange={(value: string) => {
            handleSelectOnChange(value);
            setValue('target', value);
          }}
          className='faq'
          errorStyle={error ? error : null}>
          {[
            {
              id: 1,
              nameAr: 'الشركات',
              nameEn: 'companies',
            },
            {
              id: 2,
              nameAr: 'المتسوقين',
              nameEn: 'users',
            },
            {
              id: 3,
              nameAr: 'الكل',
              nameEn: 'all',
            },
          ]?.map((item) => {
            return (
              <Option key={item.id} value={item.nameEn}>
                <OptionValue>{item.nameAr}</OptionValue>
              </Option>
            );
          })}
        </Select>
        {error && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}

        <Label>{t('page_answer')}</Label>
        <TextArea
          name='answer'
          errorName={errors.answer}
          inputRef={register({ required: true })}
          errorStyle={errors.answer}
          onChange={(e: Event) => {
            setValue('answer', e);
          }}
          style={{ width: '90%' }}
        />
        {errors?.answer && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}

        <Button
          type='primary'
          onClick={handleSubmit(onSubmit)}
          disabled={formState.isSubmitting}
          size='large'>
          <Trans>save</Trans>
        </Button>
      </Form>
    </Container>
  );
};

export default CreateFaqForm;
