/** @format */

import React, { ReactNode } from 'react';
import { StyledSelect as AntDSelect } from './style';

type Props = {
  onChange?: any | ((value?: any, index?: number) => void | any);
  defaultValue?: any;
  style?: React.CSSProperties | undefined;
  children: ReactNode;
  onSelect?: (value: string) => void;
  className?: string;
  value?: string | number | undefined | any;
  title?: string | any;
  width?: any;
  mode?: 'multiple' | 'tags' | undefined;
  allowClear?: boolean;
  placeholder?: string | ReactNode;
  showArrow?: boolean;
  errorStyle?: any;
  dropdownRender?: any | ((value?: any) => void | any);
  disabled?: boolean;
};

const Select = ({
  onChange,
  defaultValue,
  children,
  value,
  className,
  mode,
  placeholder,
  errorStyle,
  dropdownRender,
  disabled,
}: Props): JSX.Element => {
  return (
    <AntDSelect
      onChange={onChange}
      defaultValue={defaultValue}
      size='large'
      className={className}
      direction='rtl'
      value={value}
      mode={mode}
      placeholder={placeholder}
      showArrow={true}
      maxTagTextLength={1000}
      dropdownRender={dropdownRender}
      style={errorStyle}
      disabled={disabled}
    >
      {children}
    </AntDSelect>
  );
};

Select.defaultProps = {};
export default Select;
