import styled from "styled-components";
import Colors from "infrastructure/utils/colors";
import { Link } from 'react-router-dom';

export const Error = styled.p`
  font-family: Famtree;
  color: ${Colors.darkish_pink};
  margin-bottom: 10px;
  font-size: 13px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 60%;
  margin:0 auto;
  direction: rtl;
`;

export const PayForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin:0 auto;
`;

export const Label = styled.label`
  color: ${Colors.battleship_grey};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  margin-top: 2px;
`;

export const Bio = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: ${Colors.charcoal_grey_70};
`;

export const TdreeTitle = styled.div`
  font-family: Famtree;
  font-size: 48px;
  font-weight: normal;
  margin-top: 100px;
  margin-bottom: 0;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width:98%;
`;

export const Title = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  text-align: end;
  margin: 29px 0px 32px 0px;
`;

export const Container = styled.div`
  background-color: white;
  margin: 0px 24px;
  border-radius: 16px;
  box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 83vh;
  z-index: 1;
`;

export const TablesWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const MoreBtn = styled.div`
  font-family: Famtree;
  font-size: 36px;
  line-height: 0;
  margin-top: -10px;
  letter-spacing: -2px;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  height: 85px;
`;

export const SelectLabel = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  color: ${Colors.charcoal_grey};
  text-align: right;
  line-height: 51px;
`;

export const SelectWithSwitchContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 12px;
`;

export const ButtonContainer = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20%;
`;

export const P = styled.p`
  text-align: right;
  font-size: 22px;
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content:space-between;
  align-items:center;
  width: 95%;
  direction: rtl;
  padding:15px 0;
`;

export const SearchHeader = styled.div`
  display: flex;
  align-items:center;
  width: 95%;
  direction: rtl;
  padding:15px 0;
`;

export const Pargraph = styled.p`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 11px;
  font-weight: normal;
  margin-bottom:0;
`;

export const NoResultText = styled.p`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 16px;
  font-weight: normal;
  margin:20px 0;
`;

export const Span = styled.span`
  color: white;
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
`;

export const WrapperFlex = styled.div`
  display:flex;
  justify-content:center;
  align-items: start;
  margin: ${(props: { margin?: string }) => {
    return props.margin ? props.margin : '0';
  }};
`;

export const WrapperFlexBetween = styled.div`
  display: flex;
  justify-content:space-between;
  align-items:center;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items:center;
`;

export const ExcludeText = styled.span`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  margin:0 3px;
`;


export const StyledLink = styled(Link)`
font-family: Famtree;
font-size: 11px;
font-weight: normal;
display: flex;
color: ${Colors.charcoal_grey};
cursor: pointer;
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;

:hover{
color: ${Colors.dark_mint};

}
`
export const SubmitContainer = styled.div`
  display: flex;
  justify-content:flex-end;
  align-items:center;
  bottom: 0;
  height: 70px;
  margin-top:10px;
`;

export const SectionFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;
