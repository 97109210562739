
import styled from "styled-components";
import { Button } from "antd";
import Colors from "../../infrastructure/utils/colors";

const AntDButton = styled(Button)`
  background-color: ${(props: { className: string }) =>
    props.className === "circle"
      ? Colors.red
      : props.className === "previous" ||
        props.className === "payment2" ||
        props.className === "pay"
        ? Colors.white
        : props.className === "rate"
          ? Colors.dark_mint_2
          : props.className === "settings"
            ? "unset"
            : Colors.dark_mint};

  color: ${(props: { className: string }) =>
    props.className === "previous" ||
      props.className === "payment2" ||
      props.className === "settings"
      ? "black"
      : props.className === "pay" || props.className === "rate"
        ? Colors.dark_mint
        : "white"};
  border-color: ${(props: { className: string }) =>
    props.className === "circle"
      ? Colors.red
      : props.className === "previous"
        ? Colors.white
        : props.className === "rate"
          ? Colors.dark_mint_2
          : props.className === "settings"
            ? "white"
            : Colors.dark_mint};
  align-self: ${(props: { className: string }) =>
    props.className === "emptyTemplate" ? "center" : "flex-start"};
  width: ${(props: { className: string; style: string }) =>
    props.className === "circle"
      ? "30px"
      : props.className === "jobsForm" || props.className === "rate"
        ? "102px"
        : props.className === "previous"
          ? "102px"
          : props.className === "payment" || props.className === "payment2"
            ? "700px"
            : props.className === "pay"
              ? "66px"
              : props.className === "shopper"
                ? "100%"
                : props.className === "settings"
                  ? "100%"
                  : props.className === "promocode-btn"
                    ? "106px"
                    : "unset"};

  text-align: ${(props) =>
    props.className === "settings"
      ? "start"
      : props.className === "circle"
        ? "center"
        : props.className === "promocode-btn"
          ? "center"
          : props.className === "rate"
            ? "center"
            : "unset"};

  height: ${(props: { className: string }) =>
    props.className === "jobsForm" || props.className === "rate"
      ? "42px"
      : props.className === "previous"
        ? "42px"
        : props.className === "payment" || props.className === "payment2"
          ? "51px"
          : props.className === "shopper"
            ? "51px"
            : ""};
  border-radius: 10px;
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  margin-bottom: ${(props: { className: string }) =>
    props.className === "table" ? "30px" : "unset"};
  margin-top: ${(props: { className: string }) =>
    props.className === "table" || props.className === "payment2"
      ? "32px"
      : "unset"};
  margin-right: ${(props: { className: string }) =>
    props.className === "circle" ? "-65px" : "unset"};
  margin-left: ${(props: { className: string }) =>
    props.className === "circle" ? "21px" : "unset"};

  :hover {
    background-color: ${(props: { className: string }) =>
    props.className === "circle"
      ? Colors.red
      : props.className === "previous" ||
        props.className === "payment2" ||
        props.className === "pay"
        ? Colors.white
        : props.className === "rate"
          ? Colors.dark_mint_2
          : props.className === "settings"
            ? Colors.dark_mint_2
            : Colors.dark_mint};
    color: ${(props: { className: string }) =>
    props.className === "previous" ||
      props.className === "payment2" ||
      props.className === "settings"
      ? "black"
      : props.className === "pay" || props.className === "rate"
        ? Colors.dark_mint
        : "white"};

    border-color: ${(props: any) =>
    props.className === "rate" ? Colors.dark_mint_2 : null};
  }

  :visited {
    background-color: ${(props: { className: string }) =>
    props.className === "settings" ? Colors.dark_mint_2 : Colors.dark_mint};
  }

  :focus {
    background-color: ${(props: { className: string }) =>
    props.className === "circle"
      ? Colors.red
      : props.className === "previous" ||
        props.className === "payment2" ||
        props.className === "pay"
        ? Colors.white
        : props.className === "rate" || props.className === "settings"
          ? Colors.dark_mint_2
          : Colors.dark_mint};
    color: ${(props: { className: string }) =>
    props.className === "previous" ||
      props.className === "payment2" ||
      props.className === "settings"
      ? "black"
      : props.className === "pay" || props.className === "rate"
        ? Colors.dark_mint
        : "white"};

    border-color: ${(props: any) =>
    props.className === "rate" ? Colors.dark_mint_2 : null};
  }

  &.ant-btn-primary {
    background-color: ${(props: { className: string }) =>
    props.className === "circle"
      ? Colors.red
      : props.className === "previous"
        ? Colors.white
        : Colors.dark_mint};
    color: ${(props: { className: string }) =>
    props.className === "previous" ? "black" : "white"};
  }

  &:disabled {
    background-color: ${Colors.light_gray}; /* Replace with the desired color */
    color: #b5babd; /* Replace with the desired color */
    border-color: ${Colors.light_gray}; /* Replace with the desired color */
    cursor: not-allowed;
  }

`;

export default AntDButton;
