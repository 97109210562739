/** @format */

import React, { forwardRef, useState } from 'react';
import { Props } from 'infrastructure/interfaces/input.interface';
import { InputStyle } from './style';

const Input = forwardRef<HTMLInputElement, Props | any>((props, ref) => {
  const [keyboardlng, setKeyboardlng] = useState('');

  const preventMinus = (e: {
    code: string;
    preventDefault: () => void;
    target: any;
  }) => {
    if (props.type === 'number') {
      if (e.code === 'Minus') {
        e.preventDefault();
      }
      if (e.code === 'Equal') {
        e.preventDefault();
      }
      if (e.code === 'KeyE') {
        e.preventDefault();
      }
    }
  };

  const handleInputsTypeNumber = (e: {
    target: { value: string | any[]; maxLength: number };
  }) => {
    if (props.type === 'number') {
      if (e.target.value.length > e.target.maxLength) {
        e.target.value = e.target.value.slice(0, e.target.maxLength);
      }
    }
  };

  return (
    <>
      <InputStyle
        {...props}
        ref={ref}
        required
        dir='auto'
        onKeyPress={(e) => {
          preventMinus(e);
          e.charCode > 160 ? setKeyboardlng('ar') : setKeyboardlng('en');
        }}
        onInput={(e: {
          target: { value: string | any[]; maxLength: number };
        }) => handleInputsTypeNumber(e)}
        lng={keyboardlng}
      />
    </>
  );
});

export default Input;
