import { createGlobalStyle } from "styled-components";

import Famtree_Bold from "./Famtree_Bold.ttf";
import Famtree_Light from "./Famtree_Light.ttf";
import Famtree_Regular from "./Famtree_Regular.ttf";

const GlobalFonts = createGlobalStyle`
@font-face {
    font-family: 'Famtree';
    src: url(${Famtree_Bold}) format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Famtree';
    src: url(${Famtree_Light}) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Famtree';
    src: url(${Famtree_Regular}) format('truetype');
    font-weight: normal;
    font-style: normal;
}
`;

export default GlobalFonts;
