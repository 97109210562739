/** @format */

import React from 'react';
import { Trans } from 'react-i18next';

import Input from 'common/Input';
import Button from 'common/Button';
import {
  Form as StyledForm,
  Title,
  TitleContainer,
  InputsContainer,
  ButtonContainer,
} from 'pages/User/style';

import { Error, Label } from 'pages/style';

import InvisibleIcon from 'assets/svg/icon-invisible';
import VisibleIcon from 'assets/svg/icon-visible';
import { FormProps } from 'infrastructure/interfaces/forms.interface';

const Form: React.FC<FormProps> = ({
  errors,
  register,
  formState,
  onSubmit,
  passwordShown,
  togglePassowrdVisiblity,
  loading,
}): JSX.Element => {
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onSubmit();
    }
  };

  return (
    <StyledForm>
      <TitleContainer>
        <Title>
          <Trans>login</Trans>
        </Title>
      </TitleContainer>

      <InputsContainer>
        <Label>
          <Trans>email</Trans>
        </Label>
        <Input
          name='email'
          type='text'
          dir='true'
          login='true'
          ref={register({ required: true, pattern: /\S+@\S+\.\S+/ })}
          errorName={errors.email}
          width='login'
        />
        {errors?.email?.types?.required && (
          <Error>
            <Trans>email_required</Trans>
          </Error>
        )}
        {errors?.email?.types?.pattern && (
          <Error>
            <Trans>patternـisـnotـcorrect</Trans>
          </Error>
        )}
      </InputsContainer>
      <InputsContainer>
        <Label>
          <Trans>password</Trans>
        </Label>

        <Input
          name='password'
          type={passwordShown ? 'text' : 'password'}
          ref={register({ required: true, minLength: 8 })}
          width='login'
          errorName={errors.password}
          onKeyDown={onKeyDown}
          icon='withIcon'
        />
        {errors?.password?.types?.required && (
          <Error>
            <Trans>password_required</Trans>
          </Error>
        )}
        {errors?.password?.types?.minLength && (
          <Error>
            <Trans>passwordـminLength_8</Trans>
          </Error>
        )}

        {passwordShown ? (
          <VisibleIcon onClick={togglePassowrdVisiblity} />
        ) : (
          <InvisibleIcon onClick={togglePassowrdVisiblity} />
        )}
      </InputsContainer>

      <ButtonContainer>
        <Button
          disabled={formState.isSubmitting}
          size='large'
          onClick={onSubmit}
          loading={loading}
          type='primary'>
          <Trans>login</Trans>
        </Button>
      </ButtonContainer>
    </StyledForm>
  );
};

export default Form;
