import styled from "styled-components";
import { Modal } from "antd";
import Colors from "infrastructure/utils/colors";

type Props = {
  width: string;
  className: string;
};

const AntDModal = styled(Modal)`
  position:relative;

  .ant-modal-close{
    position: absolute;
    left: 0;
  }
  .ant-modal-content {
    border-radius: 24px;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  }

  font-family: Famtree;
  color: ${Colors.charcoal_grey};
  font-size: 14px;

  .ant-modal-footer {
    display: flex;
    border-top: 0px;
    direction:ltr;
  }

  .ant-modal-header {
    border-radius: 24px;
    padding: 16px 20px;
    padding-bottom: 6px;
    border-bottom: 0px;
    text-align:right;
    .ant-modal-title{
      p{

        font-size:16px;
      }
    }
  }

  .ant-btn {
    border-radius: 8px;
    background-color: ${Colors.dark_mint};
    font-family: Famtree;
    font-size: 12px;
    font-weight: bold;
    color: white;
    border-color: ${Colors.dark_mint};
  }

  .ant-btn-primary {
    background-color: ${Colors.dark_mint} !important;
    color: white !important;
    display: ${(props: Props) => (props.className ? "none" : "block")};
  }

  .ant-btn {
    background-color: ${Colors.lipstick_5};
    border-color: ${Colors.lipstick_5};
    color: ${Colors.lipstick};
    display: ${(props: Props) => (props.className ? "none" : "block")};
  }

  .ant-modal-body {
    text-align: end;
    padding: unset;
    margin: 0 20px;
  }
`;

export default AntDModal;
