import React, { FunctionComponent } from "react";
import { Result } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../Button";

const Unauthorized: FunctionComponent = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Result
      status="403"
      title="403"
      subTitle={t("unauthorized_message")}
      extra={
        <Button
          size="large"
          onClick={() => history.push("/home")}
          className="table"
          type="primary"
        >
          {t("back_to_home")}
        </Button>
      }
    />
  );
};
export default Unauthorized;
