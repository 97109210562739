import { Space } from "antd";
import styled from "styled-components";

const AntDSpace = styled(Space)`
  position: fixed;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
`;

export default AntDSpace;
