/** @format */

import React from 'react';
import ModalProps from 'infrastructure/interfaces/modal.interface';
import { Trans } from 'react-i18next';
import AntDModal from './style';

const Modal = ({
  children,
  title,
  visible,
  onOk,
  onCancel,
  width,
  display,
  loading,
  footer,
  hideOk,
  hideCancel,
  okText,
}: ModalProps) => {
  return (
    <AntDModal
      title={title}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      width={width}
      className={display}
      cancelText={<Trans>cancel</Trans>}
      okText={okText ? okText : <Trans>ok</Trans>}
      okButtonProps={{
        loading: loading,
        style: {
          display: hideOk ? 'none' : 'block',
        },
      }}
      cancelButtonProps={{
        loading: loading,
        style: {
          display: hideCancel ? 'none' : 'block',
        },
      }}
      okType='primary'
      destroyOnClose={true}
      footer={footer}
    >
      {children}
    </AntDModal>
  );
};

export default Modal;
