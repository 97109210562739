import { Link } from "react-router-dom";
import styled from "styled-components";
import Colors from "infrastructure/utils/colors";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 5% auto;
`;

export const Title = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 3px;
  text-align: end;
`;

export const SubTitle = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey_38};
`;

export const LinkStyle = styled(Link)`
  color: ${Colors.dark_mint};
  text-decoration: none;
  :hover {
    color: ${Colors.dark_mint};
  }
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
`;

export const TitleContainer = styled.div`
  margin-bottom: 47px;
  width: 450px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const InputsContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 0 0 0 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ButtonContainer = styled.div`
  display: inline-block;
  flex-direction: row;
  width: 450px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;
export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const LeftContainer = styled.div`
  flex-direcrion: column;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 80%;
  margin: 90px 11.5px 0 0;
`;

export const RightContainer = styled.div`
  flex-direction: column;
  height: 100vh;
  text-align: center;
  width: 20%;
  margin: 0 1.5px 0 0;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #cccccc26;
  background-color: ${Colors.white};
  position: Fixed;
  right: 0;
`;
