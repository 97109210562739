
import styled, { StyledComponent } from "styled-components";
import Colors from "infrastructure/utils/colors";

export const Container: StyledComponent<
  "div",
  any,
  {
    component: string;
  },
  never | any
> = styled.div`
  width: 95%;
  display: flex;
  height: 80px;
  margin: 0 auto;
  margin-bottom: ${(props: { component: string }) =>
    props.component === "changePassword" ? "unset" : "100px"};
  padding-top: 40px;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

export const P = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  text-align: end;
  margin: 0px 15px 0px 0px;
`;

export const RightContainer = styled.div`
  display: flex;
`;

export const LeftContainer = styled.div`
  display: flex;
  padding-top: 5px;
  flex-direction: row;
  justify-content: space-evenly;
  width: 10%;
  align-items: center;
`;

export const BackIcon = styled.span`
  font-size: 30px;
  margin-top: 4px;
  color: ${Colors.charcoal_grey};
  cursor: pointer;
`;

export const CancelIcon = styled.span`
  font-size: 25px;
  color: ${Colors.charcoal_grey};
`;

export const CloneIcon = styled.span`
  font-size: 25px;
  margin-right: 30px;
  color: ${Colors.charcoal_grey};
`;
