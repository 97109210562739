/** @format */

import React, { Suspense, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Login from 'pages/User/Login';
import Unauthorized from 'common/UnAuthorized';
import Spinner from 'common/LoadingIndecator';
import storage from 'infrastructure/utils/storage';

const MainLayout = React.lazy(() => import('common/Layout'));

type Props = {
  component: React.LazyExoticComponent<() => JSX.Element> | any;
  path: string | string[];
  exact: boolean;
  requestedRole: Array<string | null | undefined>;
  name: string;
};

const Protected = ({
  component: Component,
  path,
  exact,
  name,
  requestedRole = [],
  ...rest
}: Props) => {
  const isAuthed = !!storage.getItem('access_token');
  const role = storage.getItem('role');

  const authorized = requestedRole.find((val) => val && role?.includes(val));

  const AuthenticatedAndAuthorized = (props: any) => {
    return (
      <Suspense fallback={<Spinner size='large' />}>
        {name === 'CreateAdmin' ||
        name === 'EditAdmin' ||
        name === 'CreatePage' ||
        name === 'EditPage' ||
        name === 'CompaniesDetails' ||
        name === 'ShoppersDetails' ||
        name === 'EvaluationTemplatesDetails' ||
        name === 'CreateEvaluationTemplate' ||
        name === 'EditEvaluationTemplates' ||
        name === 'DiscountForm' ||
        name === 'JobsDetails' ||
        name === 'EditSummary' ? (
          <Component {...rest} {...props} />
        ) : (
          <MainLayout>
            <Component {...rest} {...props} />
          </MainLayout>
        )}
      </Suspense>
    );
  };

  return !authorized ? (
    <Route
      {...rest}
      render={() => {
        return <Unauthorized />;
      }}
    />
  ) : (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthed) {
          return <AuthenticatedAndAuthorized props={props} />;
        } else if (!isAuthed) {
          return (
            <>
              <Redirect to='/login' />;
              <Login />
            </>
          );
        }
      }}
    />
  );
};
export default Protected;
