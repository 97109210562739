/** @format */

import * as React from 'react';

function DeleteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width={21}
      height={25}
      cursor='pointer'
      viewBox='0 0 21 22'>
      <g
        stroke='#344247'
        strokeWidth={1.5}
        fill='none'
        fillRule='evenodd'
        opacity={0.54}>
        <path d='M13.368 4.421v-.263a2.894 2.894 0 10-5.789 0v.263' />
        <path
          strokeLinecap='round'
          d='M1.263 4.421h18.421M8.632 8.632v7.894m3.684-7.894v7.894M3.105 4.421L4.5 18.834a2.105 2.105 0 002.095 1.903h7.757c1.084 0 1.99-.823 2.095-1.903l1.395-14.413'
        />
      </g>
    </svg>
  );
}

export default DeleteIcon;
