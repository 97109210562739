/** @format */

import * as React from 'react';
import { Trans } from 'react-i18next';
import { Bio, TdreeTitle } from 'pages/style';
import Icon from './icon-vector';

function TadreeComponent(props: any) {
  return (
    <>
      <TdreeTitle>
        <svg
          {...props}
          xmlns='http://www.w3.org/2000/svg'
          width={110}
          height={58}
          viewBox='0 0 172 58'
          cursor='pointer'>
          <g fill='none' fillRule='evenodd'>
            <text
              fill='#344247'
              fontFamily='Famtree-Regular, Famtree'
              fontSize={48}
              transform='translate(-2 -4.5)'>
              <tspan x={0} y={48}>
                {'\u062A\u062F\u0631\u064A'}
              </tspan>
            </text>
            <path
              fill='#44C874'
              d='M154.664 45.08a23.647 23.647 0 01-11.415 2.918c-13.116 0-23.749-10.633-23.749-23.748S130.133.5 143.25.5c13.115 0 23.748 10.633 23.748 23.75a23.65 23.65 0 01-5.324 14.985l.065.07 8.618 9.57a4.571 4.571 0 01-.339 6.457 4.57 4.57 0 01-6.456-.338l-8.618-9.57a4.525 4.525 0 01-.28-.344z'
            />
            <path
              fill='#FFF'
              d='M132.867 20.31a4.572 4.572 0 006.67-6.252l-.186-.206a11.074 11.074 0 013.898-.704c6.131 0 11.101 4.97 11.101 11.101 0 6.131-4.97 11.101-11.1 11.101-6.132 0-11.102-4.97-11.102-11.1 0-1.388.254-2.716.719-3.94z'
            />
          </g>
        </svg>
      </TdreeTitle>

      {!props.withouticon && (
        <Bio>
          <Trans>we_help_you_to_monitor_your_works</Trans>
        </Bio>
      )}
      {!props.withouticon && <Icon />}
    </>
  );
}

export default TadreeComponent;
