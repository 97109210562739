/** @format */

import React from 'react';
import { useForm } from 'react-hook-form';

import axiosApiInstance from 'infrastructure/utils/api';
import { Redirect, useHistory } from 'react-router-dom';
import { LoginData } from 'infrastructure/interfaces/login.interface';
import { useQueryClient, useMutation } from 'react-query';
import {
  NotificationStatus,
  showNotification,
} from 'infrastructure/helpers/showNotifications';
import TadreeComponent from 'assets/svg/tdree-component';
import { StyledContainer, LeftContainer, RightContainer } from '../style';
import Form from './form';
import storage from 'infrastructure/utils/storage';

const { useState } = React;

const Login = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const { handleSubmit, register, errors, formState } = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
  });

  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [isLoading, setIsloading] = useState<boolean>(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const isAuthed = !!storage.getItem('access_token');
  const role = storage.getItem('role') || '';

  queryClient.setMutationDefaults('login', {
    mutationFn: (userData: LoginData) =>
      axiosApiInstance.post('api/admin/login', {
        email: userData.email,
        password: userData.password,
      }),
    onError: (err) => {
      setIsloading(false);
      if (err.response.data.statusCode === 400) {
        showNotification(
          NotificationStatus.Error,
          ' ',
          err.response.data.message
        );
      }
    },
    onSuccess: (res) => {
      storage.setItem('access_token', res.data.access_token);
      storage.setItem('role', res.data.admin.roles.toString());

      setIsloading(false);
      if (res.status === 200) {
        history.push('/home');
      }
    },
  });

  const mutation = useMutation('login');

  const onSubmit = (userData: LoginData | any) => {
    setIsloading(true);
    mutation.mutate(userData);
  };

  if (
    (isAuthed && role.includes('accountant')) ||
    role.includes('admin') ||
    role.includes('editor')
  ) {
    if (!isAuthed) {
      storage.clearItem('access_token');
      storage.clearItem('role');
      history.push('/login');
    }
    return <Redirect to='/home' />;
  }

  return (
    <StyledContainer>
      <LeftContainer>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          errors={errors}
          register={register}
          formState={formState}
          passwordShown={passwordShown}
          togglePassowrdVisiblity={togglePasswordVisiblity}
          loading={isLoading}
        />
      </LeftContainer>
      <RightContainer>
        <TadreeComponent />
      </RightContainer>
    </StyledContainer>
  );
};

export default Login;
